import { Table } from "react-bootstrap"
import LockIcon from "../../../static/images/lockIcon.png"
import LockOpenedIcon from "../../../static/images/lockOpenedIcon.png"
import EyeIcon from "mdi-react/EyeIcon"
import LanIcon from "../../../static/images/help/icon_downline.svg"
import { useHistory } from "react-router-dom"
import { useEffect, useState } from "react"
import { useAuth } from "../../../Providers/AuthProvider"
import "../../../components/ModalChildInfo/index.scss"
import ModalChildInfo from "../../../components/ModalChildInfo"
import { numberWithCommas, positiveNumberWithCommas } from "../../../utils/dataTransform"
import StringToRecolorNumber from "../../../components/StringToRecolorNumber"
import { useStorage } from "../../../Providers/StorageProvider"
import AlertCustom from "../../../components/AlertCustom"
import ModalAcceptLock from "../../../components/ModalAcceptLock"
import CurrentTime from "../../../components/CurrentTime"
import DepositPopUp from "../../../components/DepositPopUp"
import ChangePassword from "../../../components/changePassword"
import ChangeStatus from "../../../components/changeStatus"

export default ({
                  downlineData,
                  addClassWhileShowAllTable,
                  splitUrl,
                  setGetReqTrigger,
                  searchUserUrl,
                  showDWButtons,
                  isActionButtonActive
                }) => {
  const history = useHistory()
  // const flag = true;

  const {
    location: { pathname }
  } = history
  const { requestsToApi } = useAuth()
  const { userName, childrenLevel, userLevel } = useStorage()
  const [parent, setParent] = useState(splitUrl.slice(-1)[0] === "downline" ? userName : splitUrl.slice(-1)[0])
  // const [activePageNumber, setActivePageNumber] = useState(1)
  const role = sessionStorage.getItem("role")
  const [modalSettings, setModalSettings] = useState({
    isVisible: false,
    isMyChild: parent === userName,
    data: null
  })
  const [modalDepositSettings, setModalDepositSettings] = useState({
    action: "",
    isVisible: false,
    isMyChild: parent === userName,
    data: null,
    user: null
  })
  const [modalChangePassword, setModalChangePassword] = useState({
    action: "",
    isVisible: false,
    isMyChild: parent === userName,
    data: null,
    user: null
  })
  const [lockUser, setLockUser] = useState(null)
  const [alertOptions, setAlertOptions] = useState({
    message: null,
    type: "green"
  })

  const balanceTextController = (balance, field) => {
    if (!balance) return ""
    else if (balance > 0) {
      if (field === "take") return ""
      else if (field === "give") {
        return positiveNumberWithCommas(balance)
      }
    } else {
      if (field === "take") {
        return positiveNumberWithCommas(balance)
      } else if (field === "give") return ""
    }
  }

  const showUserInfo = async name => {
    try {
      const response = await requestsToApi.get(
        `agency/${userName}/agency-mgmt/downline/form-info?parent=${parent}&dl=${name}`
      )
      setModalSettings({ ...modalSettings, isVisible: true, data: response.data.result })
    } catch (error) {
      console.log(error?.message)
    }
  }
  const showDepositInfo = async (item, action) => {
    if (isActionButtonActive) {
      try {
        const response = await requestsToApi.get(
          `agency/${userName}/d-agency-mgmt/downline/actions?dl=${item.user.name}`
        )
        setModalDepositSettings({
          action: action,
          isVisible: true,
          user: item,
          data: response.data.result,
          master: parent
        })
      } catch (error) {
        console.log(error?.message)
      }
    }
  }

  const showPasswordInfo = async (item, action) => {
    if (isActionButtonActive) {
      try {
        const response = await requestsToApi.get(
          `agency/${userName}/d-agency-mgmt/downline/actions?dl=${item.user.name}`
        )
        setModalChangePassword({
          action: action,
          isVisible: true,
          user: item,
          data: response.data.result,
          master: parent
        })
      } catch (error) {
        console.log(error?.message)
      }
    }
  }

  const toggleConfirmStatus = async () => {
    try {
      const response = await requestsToApi.put(
        "agency/" + userName + "/agency-mgmt/downline/" + lockUser.name + "/betting-lock?status=" + !lockUser.isLocked
      )
      if (response.data.success) {
        setGetReqTrigger(true)
        setAlertOptions({ message: "Betting lock updated successfully.", type: "green" })
      }
    } catch (e) {
      setAlertOptions({ message: e?.message, type: "red" })
    }

    setLockUser(null)
  }

  const closeModal = success => {
    setModalSettings({ ...modalSettings, isVisible: false })
    if (success) {
      setAlertOptions({ message: `${childrenLevel} was updated successfully.`, type: "green" })
      setGetReqTrigger(true)
    }
  }

  const closeDepositModal = success => {
    setModalDepositSettings({ ...modalDepositSettings, isVisible: false })
    if (success) {
      setAlertOptions({
        message: `${modalDepositSettings.action == "deposit" ? "Deposit" : "Withdraw"} successfully.`,
        type: "green"
      })
      setGetReqTrigger(true)
    }
  }

  const closeChangePassword = success => {
    setModalChangePassword({ ...modalChangePassword, isVisible: false })
    if (success) {
      setAlertOptions({ message: `Password was updated successfully.`, type: "green" })
      setGetReqTrigger(true)
    }
  }
  const closeChangeStatus = success => {
    setModalChangePassword({ ...modalChangePassword, isVisible: false })
    if (success) {
      setAlertOptions({ message: `Status was updated successfully.`, type: "green" })
      setGetReqTrigger(true)
    }
  }

  const statusWiseSort = (array) => {
    // try {
    //   var ordering = {}; // map for efficient lookup of sortIndex
    //   var sortOrder = ['ACTIVE','INACTIVE','SUSPENDED','CLOSED'];
    //   for (var i=0; i<sortOrder.length; i++)
    //     ordering[sortOrder[i]] = i;

    //   array.sort( function(a, b) {
    //     return (ordering[a.user.status] - ordering[b.user.status]);
    //   });
    //   // console.log(array);
    //   return array;
    // }
    // catch (e) {
    //   return downlineData;
    // }
    return array
  }
  useEffect(() => {
    // statusWiseSort(downlineData);
  }, [downlineData])
  useEffect(() => {
    setModalSettings({
      ...modalSettings,
      isMyChild: splitUrl.slice(-1)[0] === "downline" ? userName : splitUrl.slice(-1)[0] === userName
    })
    setParent(splitUrl.slice(-1)[0] === "downline" ? userName : splitUrl.slice(-1)[0])
  }, [splitUrl, downlineData])


  return (
    <div className="children-table__container">
      <Table striped hover variant="dark" className="children-listing-table left-part">
        <thead>
        <tr>
          <th scope="col" className="text-align-left">
            Login Name
          </th>
          <th scope="col" className="text-align-left">
            ID
          </th>

          {["Downline", "Betting Status", "Status", "Details"].map(text => (
            <th key={Math.random()} scope="col" className="text-align-center">
              {text}
            </th>
          ))}
          {
            !showDWButtons ?
              ""
              :
              <th scope="col">Balance</th>
          }
          <th scope="col">Net Exposure</th>
          {
            !showDWButtons ?
              <>
                <th scope="col">Take</th>
                <th scope="col">Give</th>
                <th scope="col">Credit Limit</th>
              </>
              :
              <th scope="col">Available Balance</th>
          }
          {showDWButtons ? <th className="action" scope="col">Action</th> : ""}
          {userLevel !== "Agent" && !showDWButtons ?
            <th scope="col">Available Credit</th> : <> </>}
          <th scope="col" className={showDWButtons ? addClassWhileShowAllTable() : ""}>PT(%)C/F/T/H/G/X</th>
          <th scope="col" className={addClassWhileShowAllTable()}>
            Created
          </th>
          <th scope="col" className={addClassWhileShowAllTable()}>
            Last Login
          </th>
          <th scope="col" className={addClassWhileShowAllTable()}>
            Last IP
          </th>
          <th scope="col" className={addClassWhileShowAllTable()}>
            Host
          </th>
        </tr>
        </thead>
        <tbody>
        {downlineData.length ? (
          statusWiseSort(downlineData).map(item => (
            <tr key={item.user.id}>
              <td className="text-align-left">
                {
                  showDWButtons ?
                    item.user.loginName
                    :
                    <a className="no-href" onClick={() => showUserInfo(item.user.name)}>
                      {item.user.loginName}
                    </a>
                }
              </td>
              <td className="text-align-left">
                {
                  showDWButtons ?
                    item.user.name
                    :
                    <a className="no-href" onClick={() => showUserInfo(item.user.name)}>
                      {item.user.name}
                    </a>
                }
              </td>
              <td className="text-align-center">
                {item.user.level === "Member" ? (
                  "--"
                ) : (
                  <a
                    className="no-href"
                    onClick={() =>
                      history.push(pathname + (pathname.slice(-1)[0] === "/" ? "" : "/") + item.user.name)
                    }
                  >
                    <img src={LanIcon} alt="lanIcon" width="15" />
                  </a>
                )}
              </td>
              <td
                className={`children-listing-table__betting-status text-align-center 
                  ${childrenLevel === "Member" ? "disabled" : ""}`}
                onClick={() => role !== "READ_ONLY" && (childrenLevel !== "Member" && setLockUser({
                  name: item.user.name,
                  isLocked: item.extraInfo.bettingLocked
                }))}
              >
                {item.extraInfo.bettingLocked ? <img src={LockIcon} alt="" /> : <img src={LockOpenedIcon} alt="" />}
              </td>
              <td className="children-listing-table__status text-align-center">{item.user.status}</td>
              <td className="children-listing-table__details text-align-center">
                <a className="no-href" onClick={() => history.push("/secret-room/" + parent + "/" + item.user.name,
                  { lastUrl: searchUserUrl, user: item.user })}>
                  <EyeIcon size={17} />
                </a>
              </td>
              {
                !showDWButtons ?
                  ""
                  :
                  <td className="children-listing-table__credit-limit">
                    {/* {positiveNumberWithCommas(item.account.creditLimit)} */}
                    {numberWithCommas(item.account.creditLimit + item.account.settledBalance)}
                  </td>
              }
              <td className="children-listing-table__net-exposure">
                <StringToRecolorNumber>{item.account.actualNetExposure}</StringToRecolorNumber>
              </td>
              {
                !showDWButtons ?
                  <>
                    <td className="children-listing-table__take -positive">
                      {balanceTextController(item.account.settledBalance, "take")}
                    </td>
                    <td className="children-listing-table__give -negative">
                      {balanceTextController(item.account.settledBalance, "give")}
                    </td>
                    <td className="children-listing-table__credit-limit">
                      {positiveNumberWithCommas(item.account.creditLimit)}
                    </td>
                  </>
                  :
                  <td className="children-listing-table__credit-limit">
                    {/* {positiveNumberWithCommas(item.account.creditLimit)} */}
                    {numberWithCommas(item.account.creditLimit + item.account.settledBalance + item.account.actualNetExposure)}
                  </td>
              }

              {
                showDWButtons ?
                  <td className="children-listing-table__credit-limit" onClick={(e) => {
                    e.preventDefault()
                  }}>
                    {role !== "READ_ONLY" && <div className="children-listing-table__credit-limit-actions">

                      <div onClick={() => showDepositInfo(item, "deposit")}
                           className="children-listing-table__credit-limit-actions-item">
                        <a>D</a>
                      </div>
                      <div onClick={() => showDepositInfo(item, "withdraw")}
                           className="children-listing-table__credit-limit-actions-item">
                        <a>W</a>
                      </div>
                      <div onClick={() => showPasswordInfo(item, "password")}
                           className="children-listing-table__credit-limit-actions-item">
                        <a>P</a>
                      </div>
                      <div onClick={() => showPasswordInfo(item, "status")}
                           className="children-listing-table__credit-limit-actions-item">
                        <a>S</a>
                      </div>
                    </div>}
                  </td>
                  : ""
              }
              {userLevel !== "Agent" && !showDWButtons ?
                <td className="children-listing-table__available-credit">
                  {positiveNumberWithCommas(item.account.availableBalance)}
                </td> : <> </>}
              <td className={"children-listing-table__pt " + (showDWButtons ? addClassWhileShowAllTable() : "")}>
                {item.user.pt.CRICKET}/{item.user.pt.EXCHANGE_GAMES}/{item.user.pt.FOOTBALL}/
                {item.user.pt.GREYHOUND_RACING}/{item.user.pt.HORSE_RACING}/{item.user.pt.TENNIS}
              </td>
              {["createdDate", "lastLoginTime"].map(param => (
                <td key={param} className={addClassWhileShowAllTable()}>
                  {item.user[param] ? <CurrentTime format="DD.MM.YYYY HH:mm:ss">{item.user[param]}</CurrentTime> : ""}
                </td>
              ))}
              <td className={"children-listing-table__last-ip " + addClassWhileShowAllTable()}>
                {item.user.lastLoginIp}
              </td>
              <td className={"children-listing-table__last-ip " + addClassWhileShowAllTable()}>
                {item.user.host}
              </td>
            </tr>
          ))
        ) : (
          <></>
        )}
        </tbody>
      </Table>
      {modalSettings.isVisible ? <ModalChildInfo settings={modalSettings} closeFunc={closeModal} /> : <></>}
      {modalDepositSettings.isVisible ?
        <DepositPopUp settings={modalDepositSettings} closeFunc={closeDepositModal} /> : <></>}
      {modalChangePassword.isVisible && modalChangePassword.action === "password" ?
        <ChangePassword settings={modalChangePassword} closeFunc={closeChangePassword} /> : <></>}
      {modalChangePassword.isVisible && modalChangePassword.action === "status" ?
        <ChangeStatus settings={modalChangePassword} closeFunc={closeChangeStatus} /> : <></>}
      <ModalAcceptLock editingItem={lockUser} closeFunc={() => setLockUser(null)} confirmFunc={toggleConfirmStatus} />
      <AlertCustom disableFunc={() => setAlertOptions({ ...alertOptions, message: null })} type={alertOptions.type}>
        {alertOptions.message}
      </AlertCustom>
    </div>
  )
}
